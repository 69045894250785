import { CheckIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Label, Switch } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function PricingSection() {
  const { t } = useTranslation();

  const prices = {
    monthly: "4,99",
    annual: "3,99",
  };

  const includedFeatures = [
    t("PricingSection.feature1"),
    t("PricingSection.feature2"),
    t("PricingSection.feature3"),
    t("PricingSection.feature4"),
  ];

  const [enabled, setEnabled] = useState(false);

  return (
    <div className="bg-white dark:bg-slate-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            {t("PricingSection.title")}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {t("PricingSection.description")}
          </p>
        </div>
        <div className="mt-16 flex justify-center">
          <Field className="flex items-center">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
            >
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-slate-900 shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
            <Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900 dark:text-gray-100">
                {t("PricingSection.annualBilling")}
              </span>{" "}
              <span className="text-gray-500 dark:text-gray-400">
                {t("PricingSection.savePercent")}
              </span>
            </Label>
          </Field>
        </div>
        <div className="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 dark:ring-slate-700 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("PricingSection.lunarlyPremium")}
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-300">
              {t("PricingSection.premiumDescription")}
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                {t("PricingSection.whatsin")}
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>

            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 dark:text-gray-300 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-indigo-600"
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <p className="mt-6 text-sm text-gray-400">
              *{t("PricingSection.limits")}
            </p>
          </div>

          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 dark:bg-slate-800 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600 dark:text-gray-300">
                  {enabled
                    ? t("PricingSection.annualTitle")
                    : t("PricingSection.monthlyTitle")}
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    €{enabled ? prices.annual : prices.monthly}
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 dark:text-gray-300">
                    {t("PricingSection.monthly")}
                  </span>
                </p>
                <p className="mt-2 text-sm text-indigo-600 font-semibold underline underline-offset-8">
                  {t("PricingSection.tryOurTrial")}
                </p>
                <Link
                  to="/download"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("PricingSection.getAccess")}
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600 dark:text-gray-300">
                  {enabled
                    ? t("PricingSection.noteAnnual")
                    : t("PricingSection.noteMonthly")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-gray-800 dark:text-gray-400">
            {t("PricingSection.institution")}{" "}
            <Link
              to="/for-institutions"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
            >
              {t("Common.learnMore")}
              <span aria-hidden="true">→</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
