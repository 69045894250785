import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CtaSection() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="bg-indigo-700">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t("CtaSection.title")}
              <br />
              {t("CtaSection.undertitle")}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">
              {t("CtaSection.description")}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/download"
                className="rounded-md bg-white  px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                {t("Common.getStarted")}
              </Link>
              <Link
                to="/about-us"
                className="text-sm font-semibold leading-6 text-white"
              >
                {t("Common.learnMore")}
                <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
