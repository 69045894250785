import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PrivacyNotice from "../components/PrivacyNotice";

export default function ForInstitutionsPage() {
  const { t } = useTranslation();

  const getArray = (key: string): string[] => {
    const data = t(key, { returnObjects: true });
    return Array.isArray(data) ? data : [];
  };

  const infoTitle = getArray("ForInstitutionsPage.infoTitle");
  const infoDesc = getArray("ForInstitutionsPage.infoDesc");

  return (
    <div className="bg-slate-900">
      <Header />
      <PrivacyNotice />

      <div>
        <div className="dark:bg-slate-900 bg-white px-6 pt-24 sm:pt-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight dark:text-gray-100 text-gray-900 sm:text-6xl">
              {t("ForInstitutionsPage.title")}
            </h2>
            <p className="mt-6 text-lg leading-8 dark:text-gray-300 text-gray-600">
              {t("ForInstitutionsPage.description")}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-slate-900 px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            {infoDesc.map((item, index) => (
              <div className="mb-8">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                  {infoTitle[index]}
                </h1>
                <p key={index} className="mt-6 dark:text-gray-300">
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="dark:bg-slate-900 bg-white">
          <div className="px-6 py-10 sm:px-6 sm:py-16 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight dark:text-gray-100 text-gray-900 sm:text-4xl">
                {t("ForInstitutionsPage.ctaTitle")}
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 dark:text-gray-300 text-gray-600">
                {t("ForInstitutionsPage.ctaDesc")}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/contact"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full"
                >
                  {t("ForInstitutionsPage.contactUs")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
