import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
// import BlogPage from "./pages/BlogPage";
import StillWipPage from "./pages/StillWipPage";

import "./i18n";
import LegalPage from "./pages/LegalPage";
import PreLaunchPage from "./pages/PreLaunchPage";
import StoryPreview from "./pages/StoryPreview";
import ForParentsPage from "./pages/ForParentsPage";
import ForInsitutionsPage from "./pages/ForInstitutionsPage";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import ReadBlogPostPage from "./pages/ReadBlogPostPage";

const consent = localStorage.getItem("cookieConsent") === "accepted";
ReactGA.initialize([
  {
    trackingId: "G-CHWY5M804L",
    gaOptions: {
      anonymize_ip: consent,
      send_page_view: true,
    },
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (
  localStorage.theme === "dark" ||
  (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}

const PageViewTracker = () => {
  const location = useLocation();

  window.scrollTo(0, 0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PageViewTracker />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<ReadBlogPostPage languageId={2} />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/download" element={<PreLaunchPage />} />
        <Route path="/for-parents" element={<ForParentsPage />} />
        <Route path="/for-institutions" element={<ForInsitutionsPage />} />
        <Route path="/preview" element={<StoryPreview />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
