import Footer from "../components/Footer";
import Header from "../components/Header";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrivacyNotice from "../components/PrivacyNotice";

export default function AboutUsPage() {
  const { t } = useTranslation();
  return (
    <div>
      <PrivacyNotice />
      <Header />
      <div className="bg-white dark:bg-slate-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <p className="text-base font-semibold leading-7 text-indigo-600 dark:text-indigo-500">
              {t("AboutUsPage.lunarly")}
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
              {t("AboutUsPage.title")}
            </h1>
            <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 dark:text-gray-300 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>{t("AboutUsPage.section1")}</p>
                <p className="mt-8">{t("AboutUsPage.section2")}</p>
              </div>
              <div>
                <p>{t("AboutUsPage.section3")}</p>
                <p className="mt-8">{t("AboutUsPage.section4")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-slate-900">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
              {t("CtaSection.title")}
              <br />
              {t("CtaSection.undertitle")}
            </h2>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="/download"
                className="rounded-md bg-indigo-600 px-10 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Common.getStarted")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
