import { useTranslation } from "react-i18next";

export default function StatsSection() {
  const { t } = useTranslation();

  const stats = [
    {
      id: 1,
      name: t("StatsSection.stat1.name"),
      value: t("StatsSection.stat1.amount"),
    },
    {
      id: 2,
      name: t("StatsSection.stat2.name"),
      value: t("StatsSection.stat2.amount"),
    },
    {
      id: 3,
      name: t("StatsSection.stat3.name"),
      value: t("StatsSection.stat3.amount"),
    },
  ];

  return (
    <div className="bg-white dark:bg-slate-900 py-24 sm:py-32 dark:bg-slate-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-600 dark:text-gray-300 dark:text-gray-300">
                {stat.name}
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100 dark:text-gray-100 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
