import "./App.css";
import HomePage from "./pages/HomePage";
// import PrivacyNotice from "../components/PrivacyNotice";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <HomePage />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
