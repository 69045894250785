import {
  FaceSmileIcon,
  BuildingLibraryIcon,
  Cog6ToothIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

export default function FeatureSection() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("FeatureSection.feature1.name"),
      description: t("FeatureSection.feature1.description"),
      icon: Cog6ToothIcon,
    },
    {
      name: t("FeatureSection.feature2.name"),
      description: t("FeatureSection.feature2.description"),
      icon: FaceSmileIcon,
    },
    {
      name: t("FeatureSection.feature3.name"),
      description: t("FeatureSection.feature3.description"),
      icon: BuildingLibraryIcon,
    },
    {
      name: t("FeatureSection.feature4.name"),
      description: t("FeatureSection.feature4.description"),
      icon: MoonIcon,
    },
  ];
  return (
    <div className="bg-white  py-24 sm:py-32 dark:bg-slate-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            {t("FeatureSection.miniTitle")}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900  sm:text-4xl dark:text-gray-100">
            {t("FeatureSection.title")}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {t("FeatureSection.description")}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600  dark:text-gray-300">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="mt-8">
          <button
            type="button"
            onClick={() => {
              if (localStorage.getItem("cookieConsent") === "accepted") {
                ReactGA.event({
                  category: "Button",
                  action: "ReadStory_Feature",
                  label: "Features Section Read Story",
                  nonInteraction: false,
                  transport: "beacon",
                });
              }
            }}
            className="rounded-full bg-white  px-4 py-2.5 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-indigo-950  dark:ring-gray-800 dark:hover:bg-indigo-800"
          >
            <Link to="/preview">{t("FeatureSection.readExampleStory")}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
