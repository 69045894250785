import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivacyNotice from "../components/PrivacyNotice";

interface Section {
  title: string;
  paragraph: string;
}

interface BlogContent {
  introduction: string;
  sections: Section[];
  conclusion: string;
}

interface BlogDataItem {
  title: string;
  image: string;
  post_date: string;
  content: string;
}

interface BlogResponse {
  status: string;
  data: BlogDataItem[];
}

interface BlogProps {
  languageId: number;
}

const ReadBlogPostPage: React.FC<BlogProps> = ({ languageId }) => {
  const [data, setData] = useState<BlogDataItem[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://localhost:8000/api/src/api.php?ep=getstories&language_id=${languageId}`
          // `https://api.getlunarly.com/?ep=getstories&language_id=${languageId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: BlogResponse = await response.json();
        setData(result.data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [languageId]);

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  const parseContent = (content: string): BlogContent => {
    try {
      return JSON.parse(content);
    } catch {
      return { introduction: "", sections: [], conclusion: "" };
    }
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center py-4 text-red-600">Error: {error}</div>;

  if (!data || data.length === 0)
    return <div className="text-center py-4">No data available</div>;

  return (
    <div className="bg-white dark:bg-slate-900">
      <Header />
      <PrivacyNotice />
      <div className="max-w-4xl mx-auto pt-24">
        {data.map((item, index) => {
          const content = parseContent(item.content);
          return (
            <div key={index} className="mb-6">
              {item.image && (
                <div className="mb-4">
                  <img
                    src={`data:image/jpeg;base64,${item.image}`}
                    alt="Blog"
                    className="w-1/2 mx-auto h-auto rounded-lg shadow-md"
                  />
                </div>
              )}
              <div className="mt-8 text-5xl dark:text-gray-100 font-semibold text-center">
                <p>{data[0].title}</p>
              </div>
              {item.post_date && (
                <div className="text-sm dark:text-gray-500 my-4">
                  {formatDate(item.post_date)}
                </div>
              )}
              {content && (
                <div>
                  {content.introduction && (
                    <p className="dark:text-gray-400 mb-4 text-lg italic leading-8 text-justify">
                      {content.introduction}
                    </p>
                  )}
                  {content.sections.map((section, idx) => (
                    <div key={idx} className="mb-6">
                      <h2 className="text-2xl font-bold mb-2 mt-10 dark:text-indigo-300 leading-loose">
                        {section.title}
                      </h2>
                      <p className="dark:text-gray-100 text-lg mb-4 leading-8 indent-8 text-justify">
                        {section.paragraph}
                      </p>
                    </div>
                  ))}
                  {content.conclusion && (
                    <p className="dark:text-gray-400 text-lg mb-4 italic leading-8 text-justify">
                      {content.conclusion}
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default ReadBlogPostPage;
