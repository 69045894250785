import Footer from "../components/Footer";
import Header from "../components/Header";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FaqSection from "../components/FaqSection";
import PrivacyNotice from "../components/PrivacyNotice";

export default function PreLaunchPage() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <PrivacyNotice />
      <div className="bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            {t("PreLaunchPage.minititle")}
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-6xl">
            {t("PreLaunchPage.title")}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {t("PreLaunchPage.description")}
          </p>
        </div>
      </div>

      {/* <div className="bg-white dark:bg-slate-900 py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="max-w-2xl text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            <h2 className="inline sm:block">
              {t("PreLaunchPage.doNotMissOut")}
            </h2>{" "}
            <p className="inline sm:block">
              {t("PreLaunchPage.doNotMissOut2")}
            </p>
          </div>
          <form className="mt-10 max-w-md">
            <div className="flex gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                placeholder={t("PreLaunchPage.enterYourMail")}
                autoComplete="email"
                className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("PreLaunchPage.subscribe")}
              </button>
            </div>
            <p className="mt-4 text-sm leading-6 text-gray-900 dark:text-gray-100">
              {t("PreLaunchPage.weCare")}{" "}
              <Link
                to="/legal"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                {t("PreLaunchPage.privacyPolicy")}.
              </Link>
            </p>
          </form>
        </div>
      </div> */}

      <FaqSection />
      <Footer />
    </div>
  );
}
