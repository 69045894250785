import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogPanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import SkFlag from "../images/sk-flag.svg";
import HuFlag from "../images/hu-flag.svg";
import EnFlag from "../images/en-flag.svg";
import Logo from "../images/logo512.png";
import ReactGA from "react-ga4";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(
    document.documentElement.classList.contains("dark")
  );
  const { t, i18n } = useTranslation();
  const navigation = [
    { name: t("Header.home"), href: "/" },
    // { name: t("Header.blog"), href: "/blog" },
    { name: t("Header.aboutus"), href: "/about-us" },
    { name: t("Header.contact"), href: "/contact" },
    { name: t("Header.forParents"), href: "/for-parents" },
    { name: t("Header.forInstitutions"), href: "/for-institutions" },
    // { name: t("Header.legal"), href: "/legal" },
  ];

  interface IFlag {
    id: number;
    name: string;
    avatar: string;
  }
  const flags: IFlag[] = [
    {
      id: 1,
      name: t("Header.english"),
      avatar: EnFlag,
    },
    {
      id: 2,
      name: t("Header.slovak"),
      avatar: SkFlag,
    },
    {
      id: 3,
      name: t("Header.hungarian"),
      avatar: HuFlag,
    },
  ];

  const LanguageSelector = () => {
    const getCurrentFlag = (lang: string) => {
      switch (lang) {
        case "en":
          return flags.find((flag) => flag.id === 1) || flags[0];
        case "sk":
          return flags.find((flag) => flag.id === 2) || flags[0];
        case "hu":
          return flags.find((flag) => flag.id === 3) || flags[0];
        default:
          return flags[0];
      }
    };

    const [selected, setSelected] = useState<IFlag>(
      getCurrentFlag(i18n.language)
    );

    return (
      <Listbox
        value={selected}
        onChange={(e: IFlag) => {
          setSelected(e);

          if (localStorage.getItem("cookieConsent") === "accepted") {
            ReactGA.event({
              category: "Button",
              action: "LanguageChange",
              label: `Changed Language (from/to): ${selected.id}/${e.id}`,
              nonInteraction: false,
              transport: "beacon",
            });
          }

          switch (e.id) {
            case 1:
              i18n.changeLanguage("en");
              break;
            case 2:
              i18n.changeLanguage("sk");
              break;
            case 3:
              i18n.changeLanguage("hu");
              break;
            default:
              i18n.changeLanguage("en");
              break;
          }
        }}
      >
        <div className="relative mt-2 pr-10">
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white dark:bg-slate-900 py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 ">
            <span className="flex items-center">
              <img
                alt=""
                src={selected.avatar}
                className="h-5 w-5 flex-shrink-0 rounded-full"
              />
              <span className="ml-3 block truncate">{selected.name}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white dark:bg-slate-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {flags.map((flag) => (
              <ListboxOption
                key={flag.id}
                value={flag}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-100 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <img
                    alt=""
                    src={flag.avatar}
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {flag.name}
                  </span>
                </div>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    );
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        aria-label="Global"
        className="flex items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <Link to={navigation[0].href} className="-m-1.5 p-1.5">
            <span className="sr-only">Lunarly</span>
            <img alt="" src={Logo} className="h-8 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300"
          >
            <span className="sr-only">{t("Header.openMainMenu")}</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100 hover:text-gray-800 dark:hover:text-gray-300"
            >
              {item.name}
            </Link>
          ))}
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <LanguageSelector />
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white  dark:bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Lunarly</span>
              <img alt="" src={Logo} className="h-8 w-auto" />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">{t("Header.closeMenu")}</span>
              <XMarkIcon
                aria-hidden="true"
                className="h-6 w-6 dark:text-gray-300"
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-slate-800"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <LanguageSelector />
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
