import Image from "../images/readstory-app.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PrivacyNotice from "../components/PrivacyNotice";

export default function ForParentsPage() {
  const { t } = useTranslation();

  const MobileContent = ({
    title,
    description,
    right,
    image,
  }: {
    title: string;
    description: string;
    right: boolean;
    image: string;
  }) => {
    const SectionText = () => {
      return (
        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
          <h3 className="mt-10 max-w-lg text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-gray-100">
            {title}
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {description}
          </p>
        </div>
      );
    };

    const SectionPhone = () => {
      return (
        <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
          <svg
            role="img"
            viewBox="0 0 366 729"
            className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
          >
            <title>{t("HeroSection.appScreenshot")}</title>
            <defs>
              <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                <rect rx={36} width={316} height={684} />
              </clipPath>
            </defs>
            <path
              d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              fill="#4B5563"
            />
            <path
              d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              fill="#343E4E"
            />
            <foreignObject
              width={316}
              height={684}
              clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              transform="translate(24 24)"
            >
              <img alt="" src={image} />
            </foreignObject>
          </svg>
        </div>
      );
    };

    if (right) {
      return (
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <SectionText />
          <SectionPhone />
        </div>
      );
    }

    return (
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
        <SectionPhone />
        <SectionText />
      </div>
    );
  };

  const getArray = (key: string): string[] => {
    const data = t(key, { returnObjects: true });
    return Array.isArray(data) ? data : [];
  };

  const infoDesc = getArray("ForParentsPage.infoDesc");
  return (
    <div className="bg-white dark:bg-slate-900">
      <Header />
      <PrivacyNotice />

      <div>
        <div className="dark:bg-slate-900 bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight dark:text-gray-100 text-gray-900 sm:text-6xl">
              {t("ForParentsPage.title")}
            </h2>
            <p className="mt-6 text-lg leading-8 dark:text-gray-300 text-gray-600">
              {t("ForParentsPage.description")}
            </p>
          </div>
        </div>

        <MobileContent
          title={t("ForParentsPage.section1Title")}
          description={t("ForParentsPage.section1Desc")}
          right={true}
          image="/homepg-top.png"
        />
        <MobileContent
          title={t("ForParentsPage.section2Title")}
          description={t("ForParentsPage.section2Desc")}
          right={false}
          image="/createpg.png"
        />
        <MobileContent
          title={t("ForParentsPage.section3Title")}
          description={t("ForParentsPage.section3Desc")}
          right={true}
          image="/explorepg.png"
        />

        <div className="bg-white dark:bg-slate-900 px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
              {t("ForParentsPage.infoTitle")}
            </h1>

            {infoDesc.map((item, index) => (
              <p key={index} className="mt-6 dark:text-gray-300">
                {item}
              </p>
            ))}
            {/* <p className="mt-6 dark:text-gray-300">
              {t("ForParentsPage.infoDesc")}
            </p> */}
          </div>
        </div>

        <div className="dark:bg-slate-900 bg-white">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight dark:text-gray-100 text-gray-900 sm:text-4xl">
                {t("ForParentsPage.ctaTitle")}
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 dark:text-gray-300 text-gray-600">
                {t("ForParentsPage.ctaDesc")}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/download"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full"
                >
                  {t("Common.getStarted")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
