import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const PrivacyConsent = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    if (consent === "accepted") {
      setIsPrivacyOpen(false);
    } else if (consent === "rejected") {
      setIsPrivacyOpen(false);
    } else {
      setIsPrivacyOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");

    ReactGA.event({
      category: "Legal",
      action: "CookiePolicy_Accept",
      label: "Accepted cookie policy",
      nonInteraction: false,
      transport: "beacon",
    });
    setIsPrivacyOpen(false);
    window.location.reload();
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    ReactGA.event({
      category: "Legal",
      action: "CookiePolicy_Reject",
      label: "Rejected cookie policy",
      nonInteraction: false,
      transport: "beacon",
    });
    setIsPrivacyOpen(false);
  };

  return (
    <>
      {isPrivacyOpen && (
        <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-50">
          <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
            <p className="text-sm leading-6 text-gray-900">
              {t("PrivacyNotice.title")}{" "}
              <Link to="/legal" className="font-semibold text-indigo-600">
                {t("PrivacyNotice.cookiePolicy")}
              </Link>
              .
            </p>
            <div className="mt-4 flex items-center gap-x-5">
              <button
                type="button"
                onClick={handleAccept}
                className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                {t("PrivacyNotice.accept")}
              </button>
              <button
                type="button"
                onClick={handleReject}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {t("PrivacyNotice.reject")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyConsent;
