import HeroSection from "../components/HeroSection";
import FeatureSection from "../components/FeatureSection";
import CtaSection from "../components/CtaSection";
import BlogSection from "../components/BlogSection";
import StatsSection from "../components/StatsSection";
import PricingSection from "../components/PricingSection";
import FaqSection from "../components/FaqSection";

import Footer from "../components/Footer";
import Header from "../components/Header";
import PrivacyNotice from "../components/PrivacyNotice";

export default function HomePage() {
  return (
    <div>
      <Header />
      <PrivacyNotice />
      <HeroSection />
      <FeatureSection />
      <StatsSection />
      <CtaSection />
      <BlogSection />
      <PricingSection />
      <FaqSection />
      <Footer />
    </div>
  );
}
